import React from "react";
import Layout from "./components/Layout";
import GlobalStyle from "./globalStyles";

const App = ({ children }) => (
  <>
    <GlobalStyle />
    <Layout>{children}</Layout>
  </>
);

export default App;
