import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  FaBars,
  FaTimes,
  FaFacebook,
  FaTwitter,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";
import logo from "../assets/images/logos/Buffalo_Hz_White.webp";
import DropdownMenu from "./DropdownMenu";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmenuClick = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  const whoWeAreItems = [
    {
      label: "About Habitat Buffalo",
      link: "/who-we-are/about-habitat-buffalo",
    },
    { label: "Our Homes", link: "/who-we-are/our-homes" },
    { label: "Newsroom", link: "/who-we-are/newsroom" },
    { label: "Meet Habitat Buffalo", link: "/who-we-are/meet-habitat-buffalo" },
    { label: "Career Opportunities", link: "/who-we-are/career-opportunities" },
  ];

  const howToApplyItems = [
    { label: "Homeownership", link: "/how-to-apply/homeownership" },
    { label: "Home Repairs", link: "/how-to-apply/home-repairs" },
  ];

  const getInvolvedItems = [
    {
      label: "Individual Volunteer Opportunities",
      link: "/get-involved/individual-volunteer-opportunities",
    },
    {
      label: "Team Build Opportunities",
      link: "/get-involved/team-build-opportunities",
    },
    { label: "Winter Warriors", link: "/get-involved/winter-warriors" },
    {
      label: "Veterans/Military Families",
      link: "/get-involved/veterans-military-families",
    },
    {
      label: "Habitat Young Professionals",
      link: "/get-involved/habitat-young-professionals",
    },
    { label: "Faith and Community", link: "/get-involved/faith-and-community" },
    { label: "Youth Programs", link: "/get-involved/youth-programs" },
    {
      label: "Become a Habitat Buffalo Contractor",
      link: "/get-involved/become-a-habitat-buffalo-contractor",
    },
  ];

  const supportUsItems = [
    { label: "Donate Items", link: "/support-us/donate-items" },
    { label: "Donate Funds", link: "/support-us/donate-funds" },
    { label: "Donate a Car", link: "/support-us/donate-a-car" },
    {
      label: "Host Your Own Fundraiser",
      link: "/support-us/host-your-own-fundraiser",
    },
    { label: "Other Ways to Give", link: "/support-us" },
  ];

  const restoreItems = [
    { label: "Locations and Hours", link: "/restore/locations-and-hours" },
    { label: "Donate Items", link: "/restore/donate-items" },
    { label: "What We Sell", link: "/restore/what-we-sell" },
    { label: "ReStore FAQ", link: "/restore/restore-faq" },
  ];

  return (
    <Header>
      <TopBar>
        <SocialIcons>
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter />
          </a>
          <a
            href="https://www.youtube.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube />
          </a>
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
        </SocialIcons>
      </TopBar>
      <LogoContainer>
        <Link to="/">
          <img src={logo} alt="Habitat for Humanity Buffalo" />
        </Link>
        <HamburgerMenu onClick={toggleMenu}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </HamburgerMenu>
      </LogoContainer>
      <DesktopNavbar>
        <NavMenu>
          <DropdownMenu
            title="WHO WE ARE"
            items={whoWeAreItems}
            mainLink="/who-we-are"
          />
          <DropdownMenu
            title="HOW TO APPLY"
            items={howToApplyItems}
            mainLink="/how-to-apply"
          />
          <DropdownMenu
            title="GET INVOLVED"
            items={getInvolvedItems}
            mainLink="/get-involved"
          />
          <DropdownMenu
            title="SUPPORT US"
            items={supportUsItems}
            mainLink="/support-us"
          />
          <DropdownMenu
            title="RESTORE"
            items={restoreItems}
            mainLink="/restore"
          />
          <NavLink to="/get-involved/events">EVENTS</NavLink>{" "}
          {/* New main navbar link */}
          <NavLink to="/contact">CONTACT</NavLink>
          <NavLinkCTA to="/donate">DONATE</NavLinkCTA> {/* CTA Button */}
        </NavMenu>
      </DesktopNavbar>
      <MobileNavbar isOpen={isOpen}>
        <MobileNavMenu>
          <MobileNavItem onClick={() => handleSubmenuClick(0)}>
            WHO WE ARE
          </MobileNavItem>
          {openSubmenu === 0 && (
            <MobileSubMenu>
              {whoWeAreItems.map((item) => (
                <MobileSubNavLink key={item.link} to={item.link}>
                  {item.label}
                </MobileSubNavLink>
              ))}
            </MobileSubMenu>
          )}
          <MobileNavItem onClick={() => handleSubmenuClick(1)}>
            HOW TO APPLY
          </MobileNavItem>
          {openSubmenu === 1 && (
            <MobileSubMenu>
              {howToApplyItems.map((item) => (
                <MobileSubNavLink key={item.link} to={item.link}>
                  {item.label}
                </MobileSubNavLink>
              ))}
            </MobileSubMenu>
          )}
          <MobileNavItem onClick={() => handleSubmenuClick(2)}>
            GET INVOLVED
          </MobileNavItem>
          {openSubmenu === 2 && (
            <MobileSubMenu>
              {getInvolvedItems.map((item) => (
                <MobileSubNavLink key={item.link} to={item.link}>
                  {item.label}
                </MobileSubNavLink>
              ))}
            </MobileSubMenu>
          )}
          <MobileNavItem onClick={() => handleSubmenuClick(3)}>
            SUPPORT US
          </MobileNavItem>
          {openSubmenu === 3 && (
            <MobileSubMenu>
              {supportUsItems.map((item) => (
                <MobileSubNavLink key={item.link} to={item.link}>
                  {item.label}
                </MobileSubNavLink>
              ))}
            </MobileSubMenu>
          )}
          <MobileNavItem onClick={() => handleSubmenuClick(4)}>
            RESTORE
          </MobileNavItem>
          {openSubmenu === 4 && (
            <MobileSubMenu>
              {restoreItems.map((item) => (
                <MobileSubNavLink key={item.link} to={item.link}>
                  {item.label}
                </MobileSubNavLink>
              ))}
            </MobileSubMenu>
          )}
          <MobileNavLink to="/get-involved/events">EVENTS</MobileNavLink>{" "}
          {/* New main mobile navbar link */}
          <MobileNavLink to="/contact">CONTACT</MobileNavLink>
          <MobileNavLinkCTA to="/donate">DONATE</MobileNavLinkCTA>{" "}
          {/* CTA Button */}
        </MobileNavMenu>
      </MobileNavbar>
    </Header>
  );
};

const Header = styled.header`
  background-color: #00aeef;
  color: #fff;
  width: 100%;
  top: 0;
  z-index: 1000;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  align-items: center;

  a {
    color: #fff;
    margin-left: 15px;
    font-size: 18px;
    text-decoration: none;
  }

  a:hover {
    color: #ffcc00;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
  background-color: #00aeef;

  img {
    height: 80px;
  }

  @media (max-width: 1200px) {
    justify-content: space-between;
    width: 100%;
    padding: 20px;
  }
`;

const HamburgerMenu = styled.div`
  display: none;
  font-size: 2rem;
  cursor: pointer;

  @media (max-width: 1200px) {
    display: block;
    padding-right: 20px;
  }
`;

const DesktopNavbar = styled.div`
  padding-top: 5px;
  background-color: #fff;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const NavMenu = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Reduce the gap to fit all items in one line */
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 1200px;
`;

const NavLink = styled(Link)`
  color: #000;
  text-decoration: none;
  font-weight: 700;
  padding: 8px 12px; /* Slightly reduce padding */
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
  }

  @media (max-width: 1200px) {
    color: #000;
    padding: 10px 0;
  }
`;

const NavLinkCTA = styled(Link)`
  color: #fff;
  background-color: #43b02a; /* Habitat green */
  text-decoration: none;
  font-weight: 700;
  padding: 5px 22px 5px 22px; /* Reduce top/bottom padding, add left padding */
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out; /* Smooth transition for hover */

  &:hover {
    background-color: #388e20; /* Slightly darker green on hover */
  }

  @media (max-width: 1200px) {
    padding: 10px 15px;
  }
`;
const MobileNavbar = styled.nav`
  background-color: #fff;
  color: #000;
  text-decoration: none;
  padding: 10px 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  display: none;

  @media (max-width: 1200px) {
    display: ${(props) => (props.isOpen ? "block" : "none")};
  }
`;

const MobileNavMenu = styled.ul`
  list-style: none;
  padding: 0;
`;

const MobileNavItem = styled.li`
  padding: 15px;
  text-align: center;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  cursor: pointer;
`;

const MobileNavLink = styled(Link)`
  display: block;
  padding: 15px;
  text-align: center;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  text-decoration: none;
  color: #000;
`;

const MobileNavLinkCTA = styled(Link)`
  display: block;
  padding: 15px;
  text-align: center;
  border: 1px solid #ccc;
  background-color: #43b02a; /* Habitat green */
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
`;

const MobileSubMenu = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const MobileSubNavLink = styled(Link)`
  display: block;
  padding: 15px;
  text-align: center;
  border: 1px solid #ccc;
  background-color: #eaeaea;
  text-decoration: none;
  color: #000;
`;

export default Navbar;
